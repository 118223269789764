
import Vue from 'vue';
import { Horse } from '@/store/modules/horse';
import { showError } from '@/helpers/utils';
import FileInputImage from '@/components/FileInputImage.vue';
import { mdiHorseVariant } from '@mdi/js';

interface Props {
  horse: Horse;
}

interface Data {
  loading: boolean;
  imageToUpload: File | null;
}

interface Computed {
  imageUrl: string;
  mdiHorseVariant: string;
}

interface Methods {
  uploadImage(): void;
  setImage(file: File): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HorseAddPhotoCard',
  components: { FileInputImage },
  data: () => ({
    loading: false,
    imageToUpload: null
  }),
  methods: {
    uploadImage() {
      this.loading = true;
      const horseNew = Object.assign({}, this.horse);
      this.$store
        .dispatch('HorseModule/uploadImage', {
          horse: horseNew,
          imageToUpload: this.imageToUpload
        })
        .then(() => {
          this.loading = false;
          this.$emit('image-uploaded');
        })
        .catch((error) => {
          showError(error);
          this.loading = false;
        });
    },
    setImage(file) {
      this.imageToUpload = file;
    }
  },
  computed: {
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    imageUrl() {
      if (this.imageToUpload) {
        return URL.createObjectURL(this.imageToUpload);
      } else {
        return '';
      }
    }
  },
  props: {
    horse: {
      type: Object,
      required: true
    }
  }
});
