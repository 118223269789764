
import HorseModuleCard from '@/components/modules/HorseModuleCard.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import Vue, { PropType } from 'vue';
import { Horse } from '@/store/modules/horse';

interface Props {
  horse: Horse;
}

interface Computed {
  sport: ModuleItem;
  link: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'SportModuleCard',
  components: { HorseModuleCard },
  props: {
    horse: {
      type: Object as PropType<Horse>,
      required: true
    }
  },
  computed: {
    sport() {
      return MODULE_TYPES.SPORT;
    },
    link() {
      return this.horse.id + '/sport/';
    }
  }
});
