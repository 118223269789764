
import Vue from 'vue';
import { Horse } from '@/store/modules/horse';
import { mapState } from 'vuex';
import { alpha, required } from 'vuelidate/lib/validators';
import { showError } from '@/helpers/utils';
import { Validation, validationMixin } from 'vuelidate';
import { LocalizedSelectItem } from '@/helpers/types';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import DatePicker from '@/components/horse/DatePicker.vue';
import _ from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { ValidationGroups } from 'vue/types/vue';
import { numberDecimal } from '@/helpers/validations';
import { horseRaces } from '@/store/constants';
import FormDialog from '@/components/FormDialog.vue';

interface Data {
  loading: boolean;
  horseEdit: Horse;
  bornDatePicker: string;
  bornDateDisplay: string;
  valid: boolean;
}

interface Methods {
  update(): void;
  cancel(): void;
  selectedDate(date: string): void;
  updateSexCategory(): void;
}

interface Computed {
  localizedCategoryList: LocalizedSelectItem[];
  horseRaces: readonly string[];
  heightErrors: TranslateResult[];
  countryErrors: TranslateResult[];
  formValid: boolean;
}

interface Props {
  horse: Horse;
  dialog: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HorseEditProfileCard',
  components: { DatePicker, FormDialog },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    horseEdit: {
      name: { required },
      sex: { required },
      category: { required },
      race: { required },
      height: { numberDecimal },
      born: {
        country: { alpha }
      }
    }
  },
  props: {
    horse: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loading: false,
    valid: false,
    horseEdit: {
      id: '',
      name: '',
      sex: '',
      race: '',
      category: '',
      pelage: '',
      owner: '',
      notifications: null,
      height: undefined,
      born: {
        date: '',
        country: ''
      }
    },
    bornDatePicker: '',
    bornDateDisplay: ''
  }),
  async created() {
    this.horseEdit = _.cloneDeep(this.horse);
    //TODO - check if the category is part of a valid category list
    if (this.horseEdit.category === 'horse-edit-profile-card.category.donor') {
      this.horseEdit.category = '';
    }
  },
  methods: {
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.$v.$pending) {
        if (this.horseEdit.height) {
          this.horseEdit.height = parseFloat(
            this.horseEdit.height.toString().replace(',', '.')
          );
        }
        this.loading = true;
        this.$store
          .dispatch('HorseModule/updateHorse', {
            horse: this.horseEdit
          })
          .then(() => {
            this.loading = false;
            this.$emit('horse-updated');
          })
          .catch((error) => {
            showError(error);
            this.loading = false;
          });
      }
    },
    cancel() {
      this.horseEdit = _.cloneDeep(this.horse);
      this.horseEdit.category = this.horse.category;
      this.$emit('cancel');
    },
    selectedDate(date) {
      if (this.horseEdit.born) this.horseEdit.born.date = date;
    },
    updateSexCategory() {
      this.$v.horseEdit.sex?.$touch();
      this.horseEdit.category = '';
      this.$v.horseEdit.category?.$touch();
    }
  },
  computed: {
    formValid() {
      if (this.$refs.datePicker as Validation) {
        return (
          !(this.$refs.datePicker as Validation).$v.$invalid &&
          !this.$v.horseEdit.$invalid
        );
      }
      return !this.$v.horseEdit.$invalid;
    },
    ...mapState('UserModule', ['user']),
    horseRaces() {
      return horseRaces;
    },
    localizedCategoryList() {
      if (this.horseEdit.sex === 'horse-edit-profile-card.fields.female') {
        return [
          {
            label: this.$t('horse-edit-profile-card.category.matrix'),
            value: 'horse-edit-profile-card.category.matrix'
          },
          {
            label: this.$t('horse-edit-profile-card.category.sport'),
            value: 'horse-edit-profile-card.category.sport'
          },
          {
            label: this.$t('horse-edit-profile-card.category.receptor'),
            value: 'horse-edit-profile-card.category.receptor'
          }
        ];
      }
      return [
        {
          label: this.$t('horse-edit-profile-card.category.stallion'),
          value: 'horse-edit-profile-card.category.stallion'
        },
        {
          label: this.$t('horse-edit-profile-card.category.castrated'),
          value: 'horse-edit-profile-card.category.castrated'
        }
      ];
    },
    heightErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.horseEdit.height as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      !field.numberDecimal &&
        errors.push(this.$t('horse-edit-profile-card.error.height.invalid'));
      return errors;
    },
    countryErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.horseEdit.born?.country as ValidationGroups &
        Validation;
      if (!field.$dirty) return [];
      !field.alpha && errors.push(this.$t('errorMessages.shouldBeLetters'));
      return errors;
    }
  }
});
