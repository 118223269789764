
import HorseModuleCard from '@/components/modules/HorseModuleCard.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import Vue from 'vue';
import { mapState } from 'vuex';
import { Horse } from '@/store/modules/horse';
import { HorseShoe } from '@/store/modules/horseShoe';
import HorseShoeTable from '@/components/modules/horseShoe/HorseShoeTable.vue';

interface Data {
  changeDate: number | null;
}

interface Computed {
  horseShoeModule: ModuleItem;
  link: string;
}

interface Props {
  horse: Horse;
  horseShoe: HorseShoe | undefined | null;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'HorseshoeModuleCard',
  components: { HorseShoeTable, HorseModuleCard },
  data: () => ({
    changeDate: null,
    notifications: []
  }),
  async created() {
    this.changeDate = this.$dayjs().add(30, 'day').valueOf();
  },
  computed: {
    ...mapState('UserModule', ['currentSpace']),
    link() {
      return this.horse.id + '/horseshoe/';
    },
    horseShoeModule() {
      return MODULE_TYPES.HORSESHOE;
    }
  },
  props: {
    horse: {
      type: Object,
      required: true
    },
    horseShoe: {
      type: Object,
      default: undefined
    }
  }
});
