
import HorseModuleCard from '@/components/modules/HorseModuleCard.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import Vue, { PropType } from 'vue';
import { Horse } from '@/store/modules/horse';
import { ExamGlandersEIA, HealthReport } from '@/store/modules/health';
import PlaceHolderNewFeature from '@/components/PlaceHolderNewFeature.vue';
import EiaGlandersDetail from '@/components/modules/health/EiaGlandersDetail.vue';
import {
  mdiCheckBold,
  mdiFileOutline,
  mdiInformation,
  mdiMedicalBag,
  mdiPill,
  mdiStethoscope
} from '@mdi/js';

interface Computed {
  health: ModuleItem;
  currentHealthReports: HealthReport[];
  currentTreatment: string;
  link: string;
  expiryEiaGlanders: Date | null;
  expiryEiaGlanderText: string;
  showExpiryWarning: boolean;
  mdiStethoscope: string;
  mdiMedicalBag: string;
  mdiCheckBold: string;
  mdiInformation: string;
  mdiFileOutline: string;
  mdiPill: string;
}

interface Props {
  horse: Horse;
  healthReports: HealthReport[] | undefined | null;
  eiaExams: ExamGlandersEIA[] | undefined | null;
  glandersExams: ExamGlandersEIA[] | undefined | null;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'HealthModuleCard',
  components: { HorseModuleCard, PlaceHolderNewFeature, EiaGlandersDetail },
  computed: {
    mdiStethoscope() {
      return mdiStethoscope;
    },
    mdiMedicalBag() {
      return mdiMedicalBag;
    },
    mdiCheckBold() {
      return mdiCheckBold;
    },
    mdiInformation() {
      return mdiInformation;
    },
    mdiFileOutline() {
      return mdiFileOutline;
    },
    mdiPill() {
      return mdiPill;
    },
    showExpiryWarning() {
      if (!this.expiryEiaGlanders) {
        return false;
      }
      return this.$dayjs(this.expiryEiaGlanders).isBefore(new Date());
    },
    link() {
      return this.horse.id + '/health/';
    },
    health() {
      return MODULE_TYPES.HEALTH;
    },
    expiryEiaGlanders() {
      if (
        (!this.eiaExams || !this.eiaExams.length) &&
        (!this.glandersExams || !this.glandersExams.length)
      ) {
        return null;
      }

      if (
        this.eiaExams &&
        this.eiaExams.length &&
        this.glandersExams &&
        this.glandersExams.length
      ) {
        return new Date(this.eiaExams[0].dateEnd) <
          new Date(this.glandersExams[0].dateEnd)
          ? new Date(this.eiaExams[0].dateEnd)
          : new Date(this.glandersExams[0].dateEnd);
      }

      if (this.eiaExams && this.eiaExams.length) {
        return new Date(this.eiaExams[0].dateEnd);
      }
      if (this.glandersExams && this.glandersExams.length) {
        return new Date(this.glandersExams[0].dateEnd);
      }
      return null;
    },
    expiryEiaGlanderText() {
      if (!this.expiryEiaGlanders) {
        return this.$t('health-module-card.no-data').toString();
      }
      let returnText =
        this.$dayjs().diff(this.expiryEiaGlanders, 'day') < 1
          ? this.$t('health-module-card.expiry')
          : this.$t('health-module-card.expired');
      return (
        returnText.toString() +
        ' ' +
        this.$dayjs(this.expiryEiaGlanders).fromNow()
      );
    },
    currentHealthReports() {
      if (this.healthReports) {
        return this.healthReports.filter((hr) => {
          if (hr.dateEnd) {
            return false;
          }
          return (
            hr.type ===
            'health-module-screen.health-report.form.types.veterinary'
          );
        });
      } else {
        return [];
      }
    },
    currentTreatment() {
      let treatments = '';
      if (this.currentHealthReports) {
        if (this.currentHealthReports[0].lastEntry.treatments.length >= 1) {
          let reportsLenth =
            this.currentHealthReports[0].lastEntry.treatments.length;
          this.currentHealthReports[0].lastEntry.treatments.forEach(function (
            treatment,
            index
          ) {
            if (treatment.name) {
              if (index + 1 < reportsLenth) {
                treatments = treatments + treatment.name + ', ';
              } else {
                treatments = treatments + treatment.name;
              }
            }
          });
        }
      }
      return treatments;
    }
  },
  props: {
    horse: {
      type: Object as PropType<Horse>,
      required: true
    },
    healthReports: {
      type: Array as PropType<HealthReport[]>,
      default: undefined
    },
    eiaExams: {
      type: Array as PropType<ExamGlandersEIA[]>,
      default: undefined
    },
    glandersExams: {
      type: Array as PropType<ExamGlandersEIA[]>,
      default: undefined
    }
  }
});
