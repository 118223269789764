
import Vue from 'vue';
import { Genealogy } from '@/store/modules/horse';
import HorseGenealogyTree from '@/components/horse/HorseGenealogyTree.vue';
import PlaceHolderNewFeature from '@/components/PlaceHolderNewFeature.vue';

interface Props {
  motherFamily: Genealogy;
  fatherFamily: Genealogy;
}

interface Data {
  dialog: boolean;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Record<string, never>,
  Props
>({
  name: 'Genealogy',
  components: {
    HorseGenealogyTree,
    PlaceHolderNewFeature
  },
  data: () => ({
    dialog: false
  }),
  props: {
    motherFamily: {
      type: Object,
      default: null
    },
    fatherFamily: {
      type: Object,
      default: null
    }
  }
});
