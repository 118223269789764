
import AvatarCard from "@/components/AvatarCard.vue";
import DisplayInfo from "@/components/DisplayInfo.vue";
import HorseEditProfileCard from "@/components/horse/HorseEditProfileCard.vue";
import Vue from "vue";
import { Horse } from "@/store/modules/horse";
import { mapState } from "vuex";
import { shortStringDots } from "@/helpers/utils";
import { TYPE } from "@/store/modules/notification";
import { mdiDelete, mdiDotsVertical, mdiHorseVariant } from "@mdi/js";
import { SPACES_HOME } from "@/router";

interface Computed {
  horseCountry: string;
  horseBornDate: string;
  horseGenealogy: string;
  horseHeight: string;
  mdiHorseVariant: string;
  mdiDotsVertical: string;
  mdiDelete: string;
}

interface Props {
  horse: Horse;
  showAll: boolean;
}

interface Data {
  dialog: boolean;
  deleteHorseDialog: boolean;
  menu: boolean;
  loading: boolean;
}

interface Methods {
  shortenString(text: string, n: number): string;

  updateHorse(): void;

  closeEditHorseDialog(): void;

  open(): void;

  openDeleteDialog(): void;

  deleteHorseConfirm(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HorseProfileCard',
  components: { AvatarCard, DisplayInfo, HorseEditProfileCard },
  data: () => ({
    dialog: false,
    menu: false,
    deleteHorseDialog: false,
    loading: false
  }),
  props: {
    horse: {
      type: Object,
      required: true
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shortenString(text: string, n: number) {
      return text ? shortStringDots(text, n) : '';
    },
    updateHorse() {
      this.dialog = false;
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('horse-profile-card.snackbar-success'),
        type: TYPE.SUCCESS
      });
    },
    closeEditHorseDialog() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
      this.menu = false;
    },
    openDeleteDialog() {
      this.deleteHorseDialog = true;
      this.dialog = false;
      this.menu = false;
    },
    deleteHorseConfirm() {
      this.loading = true;
      this.$store
        .dispatch('HorseModule/deleteHorse', {
          horseId: this.$router.currentRoute.params.horseId
        })
        .then(() => {
          this.loading = false;
          this.deleteHorseDialog = false;
          const spaceId = this.$router.currentRoute.params.spaceId;
          this.$router.push({
            name: SPACES_HOME,
            params: { spaceId: spaceId }
          });
          this.$store.commit('NotificationModule/add', {
            msg: 'Cavalo excluido com sucesso',
            type: TYPE.SUCCESS
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$rollbar.error(error);
        });

      console.log('not implemented');
    }
  },
  computed: {
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    mdiDelete() {
      return mdiDelete;
    },
    horseCountry() {
      if (this.horse.born) {
        return this.horse.born.country ? this.horse.born.country : '';
      }
      return '';
    },
    horseBornDate() {
      if (this.horse.born) {
        return this.horse.born.date
          ? this.$dayjs(this.horse.born.date).fromNow(true)
          : '';
      }
      return '';
    },
    horseGenealogy() {
      if (this.horse.genealogy && Object.keys(this.horse.genealogy).length) {
        return (
          this.horse.genealogy.father.name +
          ',' +
          this.horse.genealogy.mother.name +
          '(' +
          this.horse.genealogy.mother.grandFather.name +
          ')'
        );
      }
      return '';
    },
    horseHeight() {
      return this.horse.height ? this.horse.height + ' m' : '';
    },
    ...mapState('UserModule', ['currentSpace'])
  }
});
