import { render, staticRenderFns } from "./HorseProfileCard.vue?vue&type=template&id=605f04f8&scoped=true"
import script from "./HorseProfileCard.vue?vue&type=script&lang=ts"
export * from "./HorseProfileCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605f04f8",
  null
  
)

export default component.exports