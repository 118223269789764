
import HorseModuleCard from '@/components/modules/HorseModuleCard.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import Vue from 'vue';
import { mapState } from 'vuex';
import { Horse } from '@/store/modules/horse';
import { Diet } from '@/store/modules/food';
import FoodTable from '@/components/modules/food/FoodTable.vue';

interface Props {
  horse: Horse;
  diet: Diet | undefined | null;
}

interface Data {
  rationDate: number | null;
}

interface Computed {
  food: ModuleItem;
  link: string;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'FoodModuleCard',
  components: { HorseModuleCard, FoodTable },
  props: {
    horse: {
      type: Object,
      required: true
    },
    diet: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    rationDate: null
  }),
  computed: {
    ...mapState('UserModule', ['currentSpace']),
    link() {
      return this.horse.id + '/food/';
    },
    food() {
      return MODULE_TYPES.FOOD;
    }
  }
});
