
import Vue, { PropType } from 'vue';
import AvatarCard from '@/components/AvatarCard.vue';
import { ModuleItem } from '@/store/constants';
import NotificationBadge from '@/components/NotificationBadge.vue';

export default Vue.extend({
  name: 'HorseModuleCard',
  components: { AvatarCard, NotificationBadge },
  props: {
    type: {
      type: Object as PropType<ModuleItem>,
      required: true
    },
    notifications: {
      type: Array as PropType<Notification[]>
    },
    link: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
});
