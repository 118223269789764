
import Vue, { PropType } from 'vue';
import { ExamGlandersEIA } from '@/store/modules/health';
import { HeaderItem } from '@/views/Horses.vue';

interface Exams extends ExamGlandersEIA {
  type: string;
}

interface Data {
  headers: HeaderItem[];
}

interface Computed {
  exams: Exams[];
}

interface Props {
  eiaList: ExamGlandersEIA[] | undefined | null;
  glandersList: ExamGlandersEIA[] | undefined | null;
}

export default Vue.extend<Data, Record<string, unknown>, Computed, Props>({
  name: 'EiaGlandersDetail',
  data: function () {
    return {
      headers: [
        {
          text: '',
          align: 'start',
          value: 'type'
        },
        {
          text: this.$i18n.tc(
            'health-module-screen.eia-mormo-table.upper-header.gathering-date'
          ),
          value: 'dateStart'
        },
        {
          text: this.$i18n.tc(
            'health-module-screen.eia-mormo-table.upper-header.number'
          ),
          value: 'examNumber'
        },
        {
          text: this.$i18n.tc(
            'health-module-screen.eia-mormo-table.upper-header.expiry-date'
          ),
          value: 'dateEnd'
        }
      ]
    };
  },
  computed: {
    exams() {
      let examItems: Exams[] = [];
      if (this.eiaList && this.eiaList.length) {
        const eiaString = this.$t(
          'health-module-screen.eia-mormo-table.left-header.eia'
        ).toString();
        let eia: Exams = { ...this.eiaList[0], type: eiaString };
        examItems.push(eia);
      }

      if (this.glandersList && this.glandersList.length) {
        const glandersString = this.$t(
          'health-module-screen.eia-mormo-table.left-header.mormo'
        ).toString();
        let glanders: Exams = { ...this.glandersList[0], type: glandersString };
        examItems.push(glanders);
      }

      return examItems;
    }
  },
  props: {
    eiaList: {
      type: Array as PropType<ExamGlandersEIA[]>,
      default: undefined
    },
    glandersList: {
      type: Array as PropType<ExamGlandersEIA[]>,
      default: undefined
    }
  }
});
