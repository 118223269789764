
import FeedCard from '@/components/FeedCard.vue';
import AvatarImage from '@/components/AvatarImage.vue';
import { CALENDAR_VIEW_TYPES, MODULE_TYPES, ModulesTypes } from '@/store/constants';
import HorseProfileCard from '@/components/horse/HorseProfileCard.vue';
import FoodModuleCard from '@/components/modules/food/FoodModuleCard.vue';
import HorseshoeModuleCard from '@/components/modules/horseShoe/HorseshoeModuleCard.vue';
import HealthModuleCard from '@/components/modules/HealthModuleCard.vue';
import SportModuleCard from '@/components/modules/SportModuleCard.vue';
import Calendar from '@/components/Calendar.vue';

import Vue from 'vue';
import { Horse } from '@/store/modules/horse';
import { FeedItem } from '@/store/modules/feed';
import { ERROR_LIST } from '@/helpers/errors';
import { Diet } from '@/store/modules/food';
import { HorseShoe } from '@/store/modules/horseShoe';
import { ExamGlandersEIA, HealthReport } from '@/store/modules/health';
import { SPACES_HOME } from '@/router';
import { CalendarEvent } from '@/store/modules/calendar';
import { SportActivity } from '@/store/modules/sport';

interface Data {
  horse: Horse | null;
  diet: Diet | null | undefined;
  horseShoe: HorseShoe | null | undefined;
  healthReports: HealthReport[] | null | undefined;
  eiaExams: ExamGlandersEIA[] | null | undefined;
  glandersExams: ExamGlandersEIA[] | null | undefined;
  sportActivities: SportActivity[] | null | undefined;
  feedItems: FeedItem[] | null | undefined;
  weekDays: number[];
  hideUpcomingFeatures: boolean;
  calendarEvents: CalendarEvent[];
}

interface Computed {
  types: ModulesTypes;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Computed,
  Record<string, never>
>({
  name: 'Horse',
  data: () => ({
    horse: null,
    diet: undefined,
    horseShoe: undefined,
    healthReports: undefined,
    eiaExams: undefined,
    glandersExams: undefined,
    sportActivities: undefined,
    hideUpcomingFeatures: true,
    weekDays: [],
    calendarInitialView: CALENDAR_VIEW_TYPES.CUSTOM_WEEK,
    feedItems: [],
    calendarEvents: []
  }),
  async created() {
    const horseId = this.$router.currentRoute.params.horseId;
    this.horse = this.$store.getters['HorseModule/getById'](horseId);
    if (this.horse) {
      const currentDay = new Date();
      const weekDays: number[] = [];
      let day = currentDay.getDay();
      for (let i = 0; i < 7; i++) {
        weekDays.push(day);
        day++;
        if (day > 6) {
          day = 0;
        }
      }
      this.weekDays = weekDays;
    } else {
      const spaceId = this.$router.currentRoute.params.spaceId;
      this.$router.push({
        name: SPACES_HOME,
        params: { spaceId: spaceId },
        query: { error: ERROR_LIST.HORSE_NO_EXISTS.code.toString() }
      });
    }
  },
  components: {
    AvatarImage,
    HealthModuleCard,
    HorseshoeModuleCard,
    FoodModuleCard,
    SportModuleCard,
    FeedCard,
    HorseProfileCard,
    Calendar
  },
  computed: {
    types() {
      return MODULE_TYPES;
    }
  },
  watch: {
    horse(newValue) {
      if (newValue) {
        this.$store
          .dispatch('FoodModule/loadActualDietFromHorse', newValue.id)
          .then((diet) => {
            this.diet = diet;
          })
          .catch(() => {
            this.diet = null;
          });
        this.$store
          .dispatch('FeedModule/loadHorseFeed', newValue.id)
          .then((feed) => {
            this.feedItems = feed;
          })
          .catch(() => {
            this.feedItems = null;
          });
        this.$store
          .dispatch('HorseShoeModule/loadActualHorseShoeFromHorse', newValue.id)
          .then((horseShoe) => {
            this.horseShoe = horseShoe;
          })
          .catch(() => {
            this.horseShoe = null;
          });
        this.$store
          .dispatch('HealthModule/loadHealthReportFromHorse', newValue.id)
          .then((healthReports) => {
            if (healthReports) {
              this.healthReports = healthReports;
            } else {
              this.healthReports = [];
            }
          })
          .catch(() => {
            this.healthReports = [];
          });
        this.$store
          .dispatch('HealthModule/loadEIAFromHorse', newValue.id)
          .then((eiaList) => {
            if (eiaList) {
              this.eiaExams = eiaList;
            } else {
              this.eiaExams = [];
            }
          })
          .catch(() => {
            this.eiaExams = [];
          });
        this.$store
          .dispatch('HealthModule/loadGlandersFromHorse', newValue.id)
          .then((glandersList) => {
            if (glandersList) {
              this.glandersExams = glandersList;
            } else {
              this.glandersExams = [];
            }
          })
          .catch(() => {
            this.glandersExams = [];
          });
        this.$store
          .dispatch('CalendarModule/loadCalendarEventsFromHorse', newValue.id)
          .then((calendarEvents) => {
            if (calendarEvents) {
              this.calendarEvents = calendarEvents;
            } else {
              this.calendarEvents = [];
            }
          })
          .catch(() => {
            this.calendarEvents = [];
          });
        this.$store
          .dispatch('SportModule/loadSportActivityFromHorse', newValue.id)
          .then((sportActivities) => {
            if (sportActivities) {
              this.sportActivities = sportActivities;
            } else {
              this.sportActivities = [];
            }
          })
          .catch(() => {
            this.sportActivities = [];
          });
      }
    }
  }
});
