
import Vue from 'vue';
import { Genealogy } from '@/store/modules/horse';
import OrganizationChart from 'vue-organization-chart';
import 'vue-organization-chart/dist/orgchart.css';

interface Data {
  datasource: FamilyTree | null;
}

interface Props {
  tree: Genealogy;
  type: string;
}

interface Methods {
  familyShort(family: Genealogy): FamilyTree | null;

  familyFull(family: Genealogy): FamilyTree | null;
}

interface FamilyTree {
  name: string;
  title: string;
  children?: FamilyTree[];
}

export default Vue.extend<Data, Methods, Record<string, never>, Props>({
  name: 'HorseGenealogyTree',
  components: {
    OrganizationChart
  },
  data: () => ({
    datasource: null
  }),
  props: {
    tree: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      required: true
    }
  },
  async created() {
    if (this.$props.type === 'full') {
      this.datasource = this.familyFull(this.$props.tree);
    } else {
      this.datasource = this.familyShort(this.$props.tree);
    }
  },
  methods: {
    familyShort(family) {
      if (family === null || family === undefined) {
        return null;
      }

      return {
        name: family.name,
        title: family.race,
        children: [
          {
            name: family.grandFather.name,
            title: family.grandFather.race
          },
          {
            name: family.grandMother.name,
            title: family.grandMother.race
          }
        ]
      };
    },
    familyFull(family) {
      return {
        name: family.name,
        title: family.race,
        children: [
          {
            name: family.grandFather.name,
            title: family.grandFather.race,
            children: [
              {
                name: family.grandFather.greatGrandFather.name,
                title: family.grandFather.greatGrandFather.race,
                children: [
                  {
                    name: family.grandFather.greatGrandFather
                      .greatGreatGrandFather.name,
                    title:
                      family.grandFather.greatGrandFather.greatGreatGrandFather
                        .race
                  },
                  {
                    name: family.grandFather.greatGrandFather
                      .greatGreatGrandMother.name,
                    title:
                      family.grandFather.greatGrandFather.greatGreatGrandMother
                        .race
                  }
                ]
              },
              {
                name: family.grandFather.greatGrandMother.name,
                title: family.grandFather.greatGrandMother.race,

                children: [
                  {
                    name: family.grandFather.greatGrandMother
                      .greatGreatGrandFather.name,
                    title:
                      family.grandFather.greatGrandMother.greatGreatGrandFather
                        .race
                  },
                  {
                    name: family.grandFather.greatGrandMother
                      .greatGreatGrandMother.name,
                    title:
                      family.grandFather.greatGrandMother.greatGreatGrandMother
                        .race
                  }
                ]
              }
            ]
          },
          {
            name: family.grandMother.name,
            title: family.grandMother.race,
            children: [
              {
                name: family.grandMother.greatGrandFather.name,
                title: family.grandMother.greatGrandFather.race,
                children: [
                  {
                    name: family.grandMother.greatGrandFather
                      .greatGreatGrandFather.name,
                    title:
                      family.grandMother.greatGrandFather.greatGreatGrandFather
                        .race
                  },
                  {
                    name: family.grandMother.greatGrandFather
                      .greatGreatGrandMother.name,
                    title:
                      family.grandMother.greatGrandFather.greatGreatGrandMother
                        .race
                  }
                ]
              },
              {
                name: family.grandMother.greatGrandMother.name,
                title: family.grandMother.greatGrandMother.race,

                children: [
                  {
                    name: family.grandMother.greatGrandMother
                      .greatGreatGrandFather.name,
                    title:
                      family.grandMother.greatGrandMother.greatGreatGrandFather
                        .race
                  },
                  {
                    name: family.grandMother.greatGrandMother
                      .greatGreatGrandMother.name,
                    title:
                      family.grandMother.greatGrandMother.greatGreatGrandMother
                        .race
                  }
                ]
              }
            ]
          }
        ]
      };
    }
  }
});
