
import Vue from 'vue';
import DisplayInfo from '@/components/DisplayInfo.vue';
import PlaceHolderNewFeature from '@/components/PlaceHolderNewFeature.vue';

interface Data {
  headHighlight: string;
}

interface Methods {
  focus(id: string): void;

  defocus(id: string): void;
}

export default Vue.extend<
  Data,
  Methods,
  Record<string, never>,
  Record<string, never>
>({
  name: 'HorseOutilineDiagram',
  components: { DisplayInfo, PlaceHolderNewFeature },
  data: () => ({
    headHighlight: ''
  }),
  props: {},
  computed: {},
  methods: {
    focus(id) {
      if (id === 'head') {
        this.headHighlight = 'color:red;';
      }
    },
    defocus(id) {
      if (id === 'head') {
        this.headHighlight = '';
      }
    }
  }
});
