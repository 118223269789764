
import { MODULE_TYPES, ModulesTypes } from "@/store/constants";
import HorseProfileCard from "@/components/horse/HorseProfileCard.vue";
import Genealogy from "@/components/horse/HorseGenealogy.vue";
import HorseAddPhotoCard from "@/components/horse/HorseAddPhotoCard.vue";
import OutlineDiagram from "@/components/horse/HorseOutlineDiagram.vue";

import Vue from "vue";
import { Horse } from "@/store/modules/horse";
import { ERROR_LIST } from "@/helpers/errors";
import { showError } from "@/helpers/utils";
import { TYPE } from "@/store/modules/notification";
import { SPACES_HOME } from "@/router";
import { mdiDotsVertical } from "@mdi/js";

interface Data {
  horseId: string | null;
  photosDialog: boolean;
  menu: boolean;
  loading: boolean;
  hideUpcomingFeatures: boolean;
  carouselIndex: number;
}

interface Methods {
  uploadedImage(): void;
  loadHorse(): void;
  setAvatar(): void;
  open(): void;
}

interface Computed {
  horse: Horse | null;
  types: ModulesTypes;
  mdiDotsVertical: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'Horse',
  data: () => ({
    horseId: null,
    photosDialog: false,
    menu: false,
    loading: false,
    hideUpcomingFeatures: true,
    carouselIndex: 0
  }),
  async created() {
    this.loadHorse();
  },
  methods: {
    uploadedImage() {
      this.photosDialog = false;
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('horse-profile.image-uploaded'),
        type: TYPE.SUCCESS
      });
    },
    setAvatar() {
      this.menu = false;
      this.loading = true;
      const horseNew = Object.assign({}, this.horse);
      if (Array.isArray(horseNew.photos)) {
        const newAvatarImage = horseNew.photos[this.carouselIndex];
        this.$store
          .dispatch('HorseModule/setAvatar', {
            horse: horseNew,
            newAvatar: newAvatarImage
          })
          .then(() => {
            this.loading = false;
            this.$store.commit('NotificationModule/add', {
              msg: this.$t('horse-profile.set-avatar'),
              type: TYPE.SUCCESS
            });
          })
          .catch((error) => {
            showError(error);
            this.loading = false;
          });
      }
    },
    loadHorse() {
      this.horseId = this.$router.currentRoute.params.horseId;
      if (!this.horseId) {
        const spaceId = this.$router.currentRoute.params.spaceId;
        this.$router.push({
          name: SPACES_HOME,
          params: { spaceId: spaceId },
          query: { error: ERROR_LIST.HORSE_NO_EXISTS.code.toString() }
        });
      }
    },
    open() {
      this.photosDialog = true;
      this.menu = false;
    }
  },
  components: {
    HorseProfileCard,
    Genealogy,
    OutlineDiagram,
    HorseAddPhotoCard
  },
  computed: {
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    horse() {
      if (this.horseId)
        return this.$store.getters['HorseModule/getById'](this.horseId);
      return null;
    },
    types() {
      return MODULE_TYPES;
    }
  }
});
