
import Vue from 'vue';
import { FeedItem } from '@/store/modules/feed';
import { translate } from '@/helpers/utils';
import AvatarImageKit from '@/components/AvatarImageKit.vue';
import { mdiHorseVariant, mdiInformation, mdiMagnify } from '@mdi/js';

interface Props {
  items: FeedItem[];
  height: number;
  showHorse: boolean;
}

interface Data {
  search: string;
  feed: FeedItem[] | null | undefined;
}

interface Methods {
  clearSearch(): void;
  sortItems(items: FeedItem[]): FeedItem[];
}

interface Computed {
  filteredItems: FeedItem[] | null | undefined;
  heightBox: number;
  mdiMagnify: string;
  mdiHorseVariant: string;
  mdiInformation: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FeedCard',
  components: { AvatarImageKit },
  data: () => ({
    search: '',
    feed: undefined
  }),
  props: {
    items: {
      type: Array,
      default: undefined
    },
    height: {
      type: Number,
      default: 400
    },
    showHorse: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
    sortItems(items) {
      return items.sort((a, b) => {
        const timeB = new Date(b.timestamp);
        const timeA = new Date(a.timestamp);
        return timeB.getTime() - timeA.getTime();
      });
    }
  },
  computed: {
    mdiMagnify() {
      return mdiMagnify;
    },
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiInformation() {
      return mdiInformation;
    },
    heightBox() {
      return this.$props.height * 0.79;
    },
    filteredItems: function () {
      if (this.feed) {
        return this.sortItems(
          this.feed.filter((item: FeedItem) => {
            if (!this.search) return this.$props.items;
            return (
              item.title
                .toString()
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              item.subTitle
                .toString()
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              (item.horse &&
                item.horse.name
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              item.type.name.toLowerCase().includes(this.search.toLowerCase())
            );
          })
        );
      } else {
        return this.feed;
      }
    }
  },
  watch: {
    items(newValue) {
      if (newValue) {
        this.feed = newValue.map((item: FeedItem) => {
          const translatedItem = Object.assign({}, item);
          translatedItem.title = translate(
            translatedItem.title.toString(),
            translatedItem.titleData
          );
          translatedItem.subTitle = translate(
            translatedItem.subTitle.toString(),
            translatedItem.subTitleData
          );
          return translatedItem;
        });
      } else {
        this.feed = newValue;
      }
    }
  }
});
